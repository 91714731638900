import React, { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Axios from 'axios';
import {
    Button, TextField, Typography, Container, Box, CssBaseline, Avatar, IconButton,
    InputAdornment, Card, CardContent, CircularProgress, Snackbar, Alert
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Logo from '../../assets/images/Logo512_BLACK.png';

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: '100%',
                },
                body: {
                    backgroundColor: 'transparent',
                    backgroundImage: 'linear-gradient(180deg, #e3f2fd 0%, #e3f2fd 100%)',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    margin: 0,
                    textAlign: 'left',
                    width: '100%',
                },
                '#root': {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }
            },
        },
    },
});

const Login = ({ setIsLoggedIn }) => {
    const params = useSearchParams().shift();
    const tenantId = params.get('tenant_id');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [organizationId, setOrganizationId] = useState(tenantId || '');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await Axios.post('/api/v1/auth/authenticate', { email, password, tenantId: organizationId })
            setIsLoggedIn(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Login failed:', error);
            setError('Failed to authenticate. Please check your credentials and try again.');
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth={false} sx={{
                minHeight: '100vh',
                background: 'linear-gradient(180deg, #A05EB5 1%, #00B5E2 80%, #6CC04A 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                margin: 0
            }}>
                <CssBaseline />
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 400,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: 2,
                        padding: 3,
                        boxShadow: 3,
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <Avatar sx={{ m: 1, background: 'transparent' }}>
                            <img src={Logo} alt="Ridecell Logo" style={{ width: '100%' }} />
                        </Avatar>
                    </Box>
                    <Typography component="h1" variant="h5">
                        <center>Developer Portal</center>
                    </Typography>
                    <Card sx={{ mt: 2, mb: 2, width: '100%' }}>
                        <CardContent>
                            <Typography variant="body1" component="p">
                                Welcome to the Ridecell Developer Portal. Please enter your email/username, password, and organization ID.
                                <br /><br />
                                The <strong>Organization ID</strong> will be provided by Ridecell. This ID is essential for accessing your data and services.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address/Username"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            disabled={loading}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="organizationId"
                            label="Organization ID"
                            type="text"
                            id="organizationId"
                            autoComplete="organization-id"
                            value={organizationId}
                            onChange={(e) => setOrganizationId(e.target.value)}
                            disabled={loading}
                            defaultValue={tenantId}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#6CC04A' }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Sign In'}
                        </Button>
                    </Box>
                    <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError('')}>
                        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
